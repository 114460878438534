import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../images/nav.png'; // Adjust the path as necessary
import './css/EddingbrookNav.css'; // Adjust the path as necessary

const EddingbrookNav = () => {
    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="eddingbrook-nav">
                <Container>
                    <Navbar.Brand href="#home">
                        <div className="brand-wrapper">
                            <img
                                src={logo}
                                id="nav-logo"
                                className="d-inline-block align-top"
                                alt="Logo"
                            />
                            <span className="brand-text">Sheet Metal Manufacturers</span>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="custom-toggler" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {/* Add other Nav elements here if needed */}
                        </Nav>
                        <Nav>
                            <Nav.Link href="#home" className="nav-link-white">Home</Nav.Link>
                            <Nav.Link href="#services" className="nav-link-white">Services</Nav.Link>
                            <Nav.Link href="#contact" className="nav-link-white">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default EddingbrookNav;
