import React from 'react';
import "./css/EddingbrookFooter.css";
import {Container, Row, Col} from 'react-bootstrap';
const EddingbrookFooter = () => {
    
        return (
            <div className="footer">
                <Container>
                    <Row>
                        <Col>
                        <p className="footer-text">© Eddingbrook Ltd. 2021</p>
                        </Col>
                        <Col>
                        <p className="footer-text">Website by <a href="https://www.williamfort.click">Will Fort</a></p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
export default EddingbrookFooter;
