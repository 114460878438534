import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./css/EddingbrookHeader.css";

const EddingbrookHeaderMain = () => {
    return (
        <>
        <Container fluid className="header-main d-flex align-items-center justify-content-center">
            <Row className="text-center">
                <Col>
                </Col>
                <Col xs={10}>
                    <h1 className="large-header">Eddingbrook Ltd.</h1>
                    <p className="sub-header mt-3">
                        A long-established sheet metal fabrication company based in Todmorden, West Yorkshire, specialising in batch production engineering and bespoke fabrications.
                    </p>
                </Col>
                <Col>
                </Col>
            </Row>
        </Container>
        </>
        
    );
}

export default EddingbrookHeaderMain;
