import React from 'react';
import EddingbrookNav from './EddingbrookNav';
import EddingbrookHeaderMain from './EddingbrookHeaderMain';
import EddingbrookFooter from './EddingbrookFooter';
import './css/Home.css'; // Import the CSS file

const Home = () => {
    return (
        <div className="home-background">
            <div className="content">
                <EddingbrookNav />
                {/* Other content can be added here */}
                <EddingbrookHeaderMain />
                <EddingbrookFooter />
            </div>
        </div>
    );
}

export default Home;
